import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`21st August 2020`}</p>


    <h2 {...{
      "id": "-new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-new-features",
        "aria-label": " new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🌟 New features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`You can now check whether a `}<a parentName="p" {...{
            "href": "type://RequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContext.html"
          }}>{`type://RequestContext`}</a>{` contains an attribute or not conveniently using
`}<a parentName="p" {...{
            "href": "type://RequestContext#hasAttr(AttributeKey):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContext.html#hasAttr(io.netty.util.AttributeKey)"
          }}>{`type://RequestContext#hasAttr(AttributeKey)`}</a>{` and `}<a parentName="p" {...{
            "href": "type://RequestContext#hasOwnAttr(AttributeKey):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContext.html#hasOwnAttr(io.netty.util.AttributeKey)"
          }}>{`type://RequestContext#hasOwnAttr(AttributeKey)`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3014"
          }}>{`#3014`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now specify the default service and log name programmatically for an annotated service. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2979"
          }}>{`#2979`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`It was previously only possible to specify a service name with `}<a parentName="li" {...{
              "href": "type://@ServiceName:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/ServiceName.html"
            }}>{`type://@ServiceName`}</a>{` annotation.`}</li>
        </ul>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Server.builder()
      .annotatedService().defaultServiceName("my-service")
                         .build(myAnnotatedService);
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Annotated services now support `}<a parentName="p" {...{
            "href": "https://kotlinlang.org/docs/reference/coroutines-overview.html"
          }}>{`Kotlin coroutines`}</a>{`.
`}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2983"
          }}>{`#2983`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3010"
          }}>{`#3010`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3011"
          }}>{`#3011`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`See `}<a parentName="li" {...{
              "href": "/docs/server-annotated-service#kotlin-coroutines-support"
            }}>{`Kotlin coroutines support`}</a>{` for more
information.`}</li>
        </ul>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-kotlin"
          }}>{`class MyAnnotatedService {
    @Get("/users/{name}")
    suspend fun getUserToken(@Param name: String): String {
        val user = myReactiveRepository.findByName(name).await()
        return user.token
    }
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now get the current `}<a parentName="p" {...{
            "href": "type://ServiceRequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceRequestContext.html"
          }}>{`type://ServiceRequestContext`}</a>{` from the Kotlin coroutine context when using
`}<a parentName="p" {...{
            "href": "https://github.com/grpc/grpc-kotlin"
          }}>{`grpc-kotlin`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3001"
          }}>{`#3001`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now use `}<a parentName="p" {...{
            "href": "type://GrpcMeterIdPrefixFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/grpc/GrpcMeterIdPrefixFunction.html"
          }}>{`type://GrpcMeterIdPrefixFunction`}</a>{` to create meters with a `}<inlineCode parentName="p">{`grpc-status`}</inlineCode>{` tag for gRPC clients
and servers. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2762"
          }}>{`#2762`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2971"
          }}>{`#2971`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Server.builder()
      .route().defaultServiceName("my-grpc-services")
              .build(GrpcService.builder()
                                .addService(serviceFoo)
                                .addService(serviceBar)
                                .build()
                                .decorate(MetricCollectingService.newDecorator(
                                    GrpcMeterIdPrefixFunction.of("my.grpc.svcs"))))
                                //  ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
      .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Added a new module `}<inlineCode parentName="p">{`armeria-reactor3`}</inlineCode>{` that provides automatic `}<a parentName="p" {...{
            "href": "type://RequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContext.html"
          }}>{`type://RequestContext`}</a>{` propagation for
`}<a parentName="p" {...{
            "href": "https://projectreactor.io/"
          }}>{`Project Reactor`}</a>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2999"
          }}>{`#2999`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`See `}<a parentName="li" {...{
              "href": "https://github.com/line/armeria-examples/tree/main/context-propagation/reactor"
            }}>{`the complete example`}</a>{`
for more information.`}</li>
        </ul>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`// Enable the hooks.
RequestContextHooks.enable();

RequestContext ctx = ...;
Flux<String> flux;
try (SafeCloseable ignored = ctx.push()) {
    // The callbacks of the 'flux' will have thread-local RequestContext set.
    flux = Flux.just("foo").publishOn(Schedulers.single());
}

StepVerifier.create(flux)
            .expectSubscriptionMatches(s -> RequestContext.current() == ctx)
            .expectNextMatches(s -> RequestContext.current() == ctx &&
                                    "foo".equals(s))
            .verifyComplete();
`}</code></pre>
      </li>
    </ul>
    <h2 {...{
      "id": "-improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-improvements",
        "aria-label": " improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📈 Improvements`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "type://RequestContext#setAttr(AttributeKey,Object)"
          }}>{`type://RequestContext#setAttr(AttributeKey,Object)`}</a>{` now returns an old value being replaced. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2997"
          }}>{`#2997`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/3004"
          }}>{`#3004`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`static final AttributeKey<String> ATTR_KEY =
    AttributeKey.valueOf(MyClass.class, "KEY");

RequestContext ctx = ...;
ctx.setAttr(ATTR_KEY, "foo");
String oldValue = ctx.setAttr(ATTR_KEY, "bar");
assert "foo".equals(oldValue);
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now bind a `}<a parentName="p" {...{
            "href": "type://ServiceWithRoutes:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceWithRoutes.html"
          }}>{`type://ServiceWithRoutes`}</a>{` (e.g. `}<a parentName="p" {...{
            "href": "type://GrpcService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcService.html"
          }}>{`type://GrpcService`}</a>{`) using the fluent builder API. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2996"
          }}>{`#2996`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`HttpService myGrpcService =
    GrpcService.builder()
               .addService(serviceFoo)
               .addService(serviceBar)
               .build()
               .decorate(LoggingService.newDecorator())
               .decorate(MetricCollectingService.newDecorator(...))

Server.builder()
      .route().defaultServiceName("my-grpc-service")
              // Note that we didn't specify any paths.
              .build(myGrpcService)
      .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`localAddress()`}</inlineCode>{` and `}<inlineCode parentName="p">{`remoteAddress()`}</inlineCode>{` builder methods in `}<a parentName="p" {...{
            "href": "type://ServiceRequestContextBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceRequestContextBuilder.html"
          }}>{`type://ServiceRequestContextBuilder`}</a>{` and
`}<a parentName="p" {...{
            "href": "type://ClientRequestContextBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientRequestContextBuilder.html"
          }}>{`type://ClientRequestContextBuilder`}</a>{` now accept `}<inlineCode parentName="p">{`SocketAddress`}</inlineCode>{` instead of `}<inlineCode parentName="p">{`InetSocketAddress`}</inlineCode>{`, so we have
more freedom in the future when we add support for UNIX domain sockets. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2996"
          }}>{`#2996`}</a></p>
      </li>
    </ul>
    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul">{`Fixed some of our `}<inlineCode parentName="li">{`Publisher`}</inlineCode>{` implementations so they do not raise an `}<inlineCode parentName="li">{`AssertionError`}</inlineCode>{` when its `}<inlineCode parentName="li">{`Subscriber`}</inlineCode>{`
attempts to request elements after the subscription is cancelled. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3003"
        }}>{`#3003`}</a></li>
      <li parentName="ul">{`The default `}<a parentName="li" {...{
          "href": "type://MeterIdPrefixFunction:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/metric/MeterIdPrefixFunction.html"
        }}>{`type://MeterIdPrefixFunction`}</a>{` now always sets the `}<inlineCode parentName="li">{`service`}</inlineCode>{` tag so that it doesn't violate the
constraints enforced by some monitoring systems, e.g. Prometheus. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2977"
        }}>{`#2977`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://TimeoutMode#SET_FROM_START:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/util/TimeoutMode.html#SET_FROM_START"
        }}>{`type://TimeoutMode#SET_FROM_START`}</a>{` now schedules a timeout correctly. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2968"
        }}>{`#2968`}</a></li>
      <li parentName="ul">{`HAProxy protocol server doesn't raise a `}<inlineCode parentName="li">{`NullPointerException`}</inlineCode>{` anymore when it receives a request with
`}<inlineCode parentName="li">{`AF_UNSPEC`}</inlineCode>{` socket family. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2984"
        }}>{`#2984`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2985"
        }}>{`#2985`}</a></li>
      <li parentName="ul">{`Fixed a bug that prevented you from overriding the service name of a gRPC or Thrift service. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2994"
        }}>{`#2994`}</a></li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`content-type`}</inlineCode>{` header of a trailer-only gRPC response is now set correctly. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2969"
        }}>{`#2969`}</a></li>
      <li parentName="ul">{`Fixed a bug where a Retrofit call is sometimes left unfinished. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3006"
        }}>{`#3006`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3008"
        }}>{`#3008`}</a></li>
      <li parentName="ul">{`A Spring WebFlux integration doesn't leave noisy error logs anymore for a `}<inlineCode parentName="li">{`HEAD`}</inlineCode>{` route that produces
content. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2965"
        }}>{`#2965`}</a></li>
      <li parentName="ul">{`Fixed a bug where a Spring WebFlux server fails to start if the `}<inlineCode parentName="li">{`management.server.port`}</inlineCode>{` property is
specified. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2980"
        }}>{`#2980`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-breaking-changes",
        "aria-label": "️ breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`☢️ Breaking changes`}</h2>
    <ul>
      <li parentName="ul">{`Removed all deprecated classes, fields and methods from the public API.`}
        <ul parentName="li">
          <li parentName="ul">{`The `}<inlineCode parentName="li">{`-Dcom.linecorp.armeria.useLegacyMeterNames`}</inlineCode>{` flag does not work anymore.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Removed `}<inlineCode parentName="li">{`HTTP_`}</inlineCode>{` or `}<inlineCode parentName="li">{`Http`}</inlineCode>{` from various fields and methods.`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://ClientOptions:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientOptions.html"
            }}>{`type://ClientOptions`}</a>{`:`}
            <ul parentName="li">
              <li parentName="ul"><inlineCode parentName="li">{`HTTP_HEADERS`}</inlineCode>{` → `}<inlineCode parentName="li">{`HEADERS`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`httpHeaders()`}</inlineCode>{` → `}<inlineCode parentName="li">{`headers()`}</inlineCode></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://Clients:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/Clients.html"
            }}>{`type://Clients`}</a>{`:`}
            <ul parentName="li">
              <li parentName="ul"><inlineCode parentName="li">{`withHttpHeader()`}</inlineCode>{` → `}<inlineCode parentName="li">{`withHeader()`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`withHttpHeaders()`}</inlineCode>{` → `}<inlineCode parentName="li">{`withHeaders()`}</inlineCode></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://AbstractClientOptionsBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/AbstractClientOptionsBuilder.html"
            }}>{`type://AbstractClientOptionsBuilder`}</a>{` and all its subtypes`}
            <ul parentName="li">
              <li parentName="ul"><inlineCode parentName="li">{`setHttpHeader()`}</inlineCode>{` → `}<inlineCode parentName="li">{`setHeader()`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`setHttpHeaders()`}</inlineCode>{` → `}<inlineCode parentName="li">{`setHeaders()`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`addHttpHeader()`}</inlineCode>{` → `}<inlineCode parentName="li">{`addHeader()`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`addHttpHeaders()`}</inlineCode>{` → `}<inlineCode parentName="li">{`addHeaders()`}</inlineCode></li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">{`Removed redundant `}<inlineCode parentName="li">{`add`}</inlineCode>{` prefix to some builder methods.`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://RequestContextExporterBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/RequestContextExporterBuilder.html"
            }}>{`type://RequestContextExporterBuilder`}</a>
            <ul parentName="li">
              <li parentName="ul"><inlineCode parentName="li">{`addBuiltIn()`}</inlineCode>{` → `}<inlineCode parentName="li">{`builtIn()`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`addAttribute()`}</inlineCode>{` → `}<inlineCode parentName="li">{`attr()`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`addRequestHeader()`}</inlineCode>{` → `}<inlineCode parentName="li">{`requestHeader()`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`addResponseHeader()`}</inlineCode>{` → `}<inlineCode parentName="li">{`responseHeader()`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`addKeyPattern()`}</inlineCode>{` → `}<inlineCode parentName="li">{`keyPattern()`}</inlineCode></li>
            </ul>
          </li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "type://KeyStoreCredentialResolverBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/saml/KeyStoreCredentialResolverBuilder.html"
            }}>{`type://KeyStoreCredentialResolverBuilder`}</a>
            <ul parentName="li">
              <li parentName="ul"><inlineCode parentName="li">{`addKeyPassword()`}</inlineCode>{` → `}<inlineCode parentName="li">{`keyPassword()`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`addKeyPasswords()`}</inlineCode>{` → `}<inlineCode parentName="li">{`keyPasswords()`}</inlineCode></li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">{`Renamed the methods in `}<a parentName="li" {...{
          "href": "type://DocService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocService.html"
        }}>{`type://DocService`}</a>{`, `}<a parentName="li" {...{
          "href": "type://DocServiceBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/docs/DocServiceBuilder.html"
        }}>{`type://DocServiceBuilder`}</a>{` and their related classes.`}
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`exampleHttpHeaders`}</inlineCode>{` → `}<inlineCode parentName="li">{`exampleHeaders`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`exampleRequest()`}</inlineCode>{` → `}<inlineCode parentName="li">{`exampleRequests()`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`exampleRequestForMethod()`}</inlineCode>{` → `}<inlineCode parentName="li">{`exampleRequests()`}</inlineCode>
            <ul parentName="li">
              <li parentName="ul">{`Removed `}<inlineCode parentName="li">{`exampleRequests(Object...)`}</inlineCode>{` to avoid ambiguity.`}</li>
            </ul>
          </li>
          <li parentName="ul"><inlineCode parentName="li">{`injectedScript()`}</inlineCode>{` → `}<inlineCode parentName="li">{`injectedScripts()`}</inlineCode></li>
          <li parentName="ul">{`Removed `}<inlineCode parentName="li">{`http_`}</inlineCode>{` from all query parameters`}
            <ul parentName="li">
              <li parentName="ul"><inlineCode parentName="li">{`http_headers`}</inlineCode>{` → `}<inlineCode parentName="li">{`headers`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`http_headers_sticky`}</inlineCode>{` → `}<inlineCode parentName="li">{`sticky_headers`}</inlineCode></li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">{`Renamed the methods in `}<a parentName="li" {...{
          "href": "type://ServerListenerBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerListenerBuilder.html"
        }}>{`type://ServerListenerBuilder`}</a>{`.`}
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`addStartingCallback()`}</inlineCode>{` → `}<inlineCode parentName="li">{`whenStarting()`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`addStartedCallback()`}</inlineCode>{` → `}<inlineCode parentName="li">{`whenStarted()`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`addStopingCallback()`}</inlineCode>{` → `}<inlineCode parentName="li">{`whenStoping()`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`addStopedCallback()`}</inlineCode>{` → `}<inlineCode parentName="li">{`whenStoped()`}</inlineCode></li>
          <li parentName="ul">{`Removed all methods that accept `}<inlineCode parentName="li">{`Runnable`}</inlineCode>{` in favor of those that
accept `}<inlineCode parentName="li">{`Consumer`}</inlineCode>{`.`}</li>
          <li parentName="ul">{`Fixed the type parameters of `}<inlineCode parentName="li">{`Iterable`}</inlineCode>{`s.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Renamed the methods in `}<a parentName="li" {...{
          "href": "type://EncodingServiceBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/encoding/EncodingServiceBuilder.html"
        }}>{`type://EncodingServiceBuilder`}</a>{`.`}
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`encodableContentTypePredicate()`}</inlineCode>{` → `}<inlineCode parentName="li">{`encodableContentTypes()`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`encodableRequestHeadersPredicate()`}</inlineCode>{` → `}<inlineCode parentName="li">{`encodableRequestHeaders()`}</inlineCode></li>
        </ul>
      </li>
      <li parentName="ul">{`Renamed the methods in `}<a parentName="li" {...{
          "href": "type://TokenBucketThrottlingStrategyBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/throttling/bucket4j/TokenBucketThrottlingStrategyBuilder.html"
        }}>{`type://TokenBucketThrottlingStrategyBuilder`}</a>{`.`}
        <ul parentName="li">
          <li parentName="ul">{`Removed the `}<inlineCode parentName="li">{`with`}</inlineCode>{` from the builder methods.`}
            <ul parentName="li">
              <li parentName="ul"><inlineCode parentName="li">{`withMinimumBackoff()`}</inlineCode>{` → `}<inlineCode parentName="li">{`minimumBackoff()`}</inlineCode></li>
              <li parentName="ul"><inlineCode parentName="li">{`withHeadersScheme()`}</inlineCode>{` → `}<inlineCode parentName="li">{`headersScheme()`}</inlineCode></li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://BasicToken#toHeaderValue()"
        }}>{`type://BasicToken#toHeaderValue()`}</a>{`, `}<a parentName="li" {...{
          "href": "type://OAuth1aToken#toHeaderValue()"
        }}>{`type://OAuth1aToken#toHeaderValue()`}</a>{` and `}<a parentName="li" {...{
          "href": "type://OAuth2Token#toHeaderValue()"
        }}>{`type://OAuth2Token#toHeaderValue()`}</a>{`
have been renamed to `}<inlineCode parentName="li">{`asHeaderValue()`}</inlineCode>{` for consistency. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3012"
        }}>{`#3012`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://RequestLogProperty#NAME:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/logging/RequestLogProperty.html#NAME"
        }}>{`type://RequestLogProperty#NAME`}</a>{` is not always available for annotated service methods anymore. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2979"
        }}>{`#2979`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Use `}<a parentName="li" {...{
              "href": "type://RequestLog#whenComplete()"
            }}>{`type://RequestLog#whenComplete()`}</a>{` as usual.`}</li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`RequestContext.setAttrIfAbsent()`}</inlineCode>{` and `}<inlineCode parentName="li">{`computeAttrIfAbsent()`}</inlineCode>{` have been removed. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2997"
        }}>{`#2997`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/3004"
        }}>{`#3004`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Use `}<a parentName="li" {...{
              "href": "type://RequestContext#setAttr(AttributeKey,Object)"
            }}>{`type://RequestContext#setAttr(AttributeKey,Object)`}</a>{` guarded by `}<a parentName="li" {...{
              "href": "type://RequestContext#hasOwnAttr(AttributeKey):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/RequestContext.html#hasOwnAttr(io.netty.util.AttributeKey)"
            }}>{`type://RequestContext#hasOwnAttr(AttributeKey)`}</a>{`.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "-dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-dependencies",
        "aria-label": " dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`⛓ Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Dropwizard Metrics 4.1.11 → 4.1.12.1`}</li>
      <li parentName="ul">{`gRPC 1.31.0 → 1.31.1`}</li>
      <li parentName="ul">{`grpc-kotlin 0.1.4 → 0.1.5`}</li>
      <li parentName="ul">{`kafka-clients 2.5.0 → 2.6.0`}</li>
      <li parentName="ul">{`Micrometer 1.5.3 → 1.5.4, 1.3.11 → 1.3.12`}</li>
      <li parentName="ul">{`Project Reactor 3.3.8 → 3.3.9`}</li>
      <li parentName="ul">{`RxJava 3.0.5 → 3.0.6`}</li>
      <li parentName="ul">{`Spring Boot 2.3.2 → 2.3.3`}</li>
      <li parentName="ul">{`Shaded dependencies:`}
        <ul parentName="li">
          <li parentName="ul">{`fastutil 8.4.0 → 8.4.1`}</li>
          <li parentName="ul">{`JCTools 3.0.0 → 3.1.0`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Examples:`}
        <ul parentName="li">
          <li parentName="ul">{`scalapb-runtime 0.10.7 → 0.10.8`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['anuraaga', 'cj848', 'cnabro', 'eunchan-kim', 'gary-lo', 'ikhoon', 'jorgheymans', 'jrhee17', 'KarboniteKream', 'matsumana', 'minwoox', 'okue', 'TimurKasatkin', 'trustin', 'xmeng1']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      